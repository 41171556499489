<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Welcome to EduPro-CRM! 👋
        </b-card-title>
        <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

        <!-- form -->
        <g-form @submit="login">
          <!-- email -->
          <label>{{ $t('email') }}</label>
          <g-field
            id="login-email"
            :value.sync="userName"
            placeholder=""
            label="email"
            name="email"
          />
          <!-- forgot password -->
          <b-form-group>
            <label>{{ $t('password') }}</label>
            <g-field
              label="Password"
              :value.sync="password"
              name="login-password"
              :type="passwordFieldType"
              placeholder="············"
            />
          </b-form-group>

          <!-- checkbox -->
          <b-form-group>
            <b-form-checkbox
              id="remember-me"
              v-model="status"
              name="checkbox-1"
            >
              {{ $t('rememberMe') }}
            </b-form-checkbox>
          </b-form-group>

          <!-- submit buttons -->
          <b-button type="submit" variant="primary" block>
            {{ $t('Sign in') }}
          </b-button>
        </g-form>
        <p class="text-center mt-2">
          <span>{{ $t('haveNotAccount') }}</span>
          <b-link :to="{ name: 'register' }">
            <span>&nbsp;{{ $t('register') }}</span>
          </b-link>
        </p>
        <!-- </b-col>
      </b-col> -->
        <!-- /Login-->
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
/* eslint-disable global-require */
// import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormCheckbox,
  // BCardText,
  BCardTitle,
  BImg,
  BButton,
} from 'bootstrap-vue';
// import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    // BRow,
    // BCol,
    BLink,
    BFormGroup,
    BFormCheckbox,
    // BCardText,
    BCardTitle,
    // BImg,
    BButton,
    // VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      updatedUserName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      token: '',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      loginApi: 'auth/login',
    }),
    login() {
      this.loginApi({ email: this.userName, password: this.password });
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'error',
        },
      });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
